<template>
  <el-dialog :title="title" :visible="dialogVisible" width="450px" append-to-body @open="open" @close="close">
    <!-- <el-form ref="form" :model="form" label-position="right">
      <el-form-item label="" prop="label" class="solt-search-c">
        <el-input v-model="form.label" placeholder="运力标签" clearable maxlength="30">
          <div slot="append" class="search-btn" @click="search">搜索</div>
        </el-input>
      </el-form-item>
    </el-form> -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      max-height="300px"
      tooltip-effect="dark"
      row-key="id"
      style="width: 100%"
      @select="handleSelectionChange"
      @select-all="selectAll"
    >
      <!-- :selectable="checkSelectable" -->
      <el-table-column
        :reserve-selection="true"
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column label="标签" prop="productName">
        <template v-slot="{ row }">
          <div class="ellipsis">{{ row.resValue }} ({{ row.resTotal }})</div>
        </template>
      </el-table-column>
      <el-table-column label="" prop="productName" align="right" width="55">
        <template v-slot="{ row }">
          <el-popover
            ref="popover"
            placement="bottom"
            width="213"
            trigger="click"
            popper-class="edit-label-pop"
          >
            <div class="edit-label">
              <div class="title">更改标签名称</div>
              <el-form ref="form" :model="editForm" rule="editRule">
                <el-form-item label="" prop="resValue">
                  <el-input v-model="editForm.resValue" placeholder="运力标签" clearable maxlength="30" />
                </el-form-item>
              </el-form>
              <div class="btn-wrap">
                <el-button size="mini" @click="cancelEdit">取消</el-button>
                <el-button size="mini" type="primary" @click="editSubmit(row)">确定</el-button>
              </div>
            </div>
            <!-- <el-button slot="reference">click 激活</el-button> -->
            <!-- <el-tooltip effect="dark" content="编辑" placement="top"> -->
            <i slot="reference" class="iconfont icon-edit-line hover-main" @click="edit(row)" />
            <!-- </el-tooltip> -->
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <div class="select-num">已选 {{ selectData.length }} 个</div>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">删除</el-button>
    </span>
  </el-dialog>
</template>

<script>
// addTypeCode,editTypeCode,deleteTypeCode ,batchDeleteTypeCode
import { getTypeCode, editTypeCode, batchDeleteTypeCode } from '@/api/typeCode';
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return '管理运力标签';
      },
    },
  },
  data() {
    return {
      // form: {},
      tableData: [],
      selectData: [],
      editForm: {},
      editRule: {
        resValue: [{ required: true, message: '请输入标签名称', trigger: 'change' }],
      },
      editActiveData: {},
    };
  },
  methods: {
    getList() {
      const params = {
        resType: 6,
      };
      getTypeCode(params)
        .then((res) => {
          this.tableData = res || [];
        });
    },
    open() {
      this.getList();
    },
    close() {
      // this.$refs.form.resetFields();
      this.$emit('update:dialogVisible', false);
    },
    handleSelectionChange(data, row) {
      this.selectData = data;
    },
    selectAll(data) {
      this.selectData = data;
    },
    submit() {
      if (!this.selectData.length) {
        this.$baseMessage('您还未勾选,请先勾选!', 'error');
        return;
      }
      const params = {
        ids: this.selectData.map((d) => d.id) || null,
      };
      batchDeleteTypeCode(params).then((res) => {
        this.$baseMessage('删除成功!', 'success');
        this.selectData = [];
        this.getList();
        this.$parent.getLabelData();
      });
    },
    edit(e) {
      this.editActiveData = JSON.parse(JSON.stringify(e));
      this.editForm = this.editActiveData;
    },
    cancelEdit() {
      // this.$refs.popover.doClose();
      document.body.click();
    },
    editSubmit(e) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // const uniqueId = new Date().getTime();
          const params = {
            ...this.editForm,
            // resCode: this.editForm.resValue,
          };
          editTypeCode(params).then(res => {
            this.$baseMessage('编辑成功!', 'success');
            this.getList();
            this.$parent.getLabelData();
            // this.$refs.popover.doClose();
            document.body.click();
          });
        }
      });
    },
  },
};

</script>
// <style lang='scss' scoped>
// .form-item{
//   margin-bottom: 0;
// }
// ::v-deep{
//   .el-form-item__error{
//     position: relative;
//   }
// }
.select-num{
  position: absolute;
  left: 16px;
  font-size: 14px;
  color: $grey-text;
}
.edit-label-pop{
  .edit-label{
    line-height: 24px;
    // font-size: 18px;
    color: #303133;
    font-size: 16px;
    font-weight: bolder;
    .title{
      margin-bottom: 10px;
    }
  }
  .btn-wrap{
    text-align: right;
  }
}
</style>
