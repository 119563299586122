<template>
  <el-drawer
    :title="drawerTitle"
    custom-class="g-drawer"
    :wrapper-closable="false"
    :visible="drawerVisible"
    size="600px"
    @open="open"
    @close="close"
  >
    <div class="g-drawer-c">
      <el-form ref="form" :model="drawerForm" label-width="80px" :rules="rules" label-position="right" :disabled="formDisabled">
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item label="关联用户" prop="ext1">
              <div style="line-height: 15px;">
                <UserInfoCard :need-change-user-info="false" :need-match="false" :user-info="companyInfo" :show-bg="true" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预装" prop="ext1">
              <el-input v-model="drawerForm.truckTotalTon" placeholder="请输入" clearable maxlength="16" @input="validateNumber('truckTotalTon', 16)">
                <template v-slot:append>
                  <span class="unit">吨</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="运力标签" prop="resType">
          <el-select
            v-model="drawerForm.resType"
            class="g-inp"
            clearable
            multiple
            placeholder="请选择"
            @change="changeLabel"
            @visible-change="handleVisibleChange"
          >
            <template #empty>
              <LabelList ref="labelList" :res-type="6" res-type-desc="运力标签" :init-label-data="initLabelData" @selectLabel="selectLabel" />
            </template>
          </el-select>
        </el-form-item>
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item label="车牌号" prop="truckId">
              <query-select v-model="drawerForm.truckId" placeholder="请选择车牌号码" value-key="id" value-label="truckNo" :default-label="drawerForm.truckNo" clearable :search-fields="selectSearchFields.truck" :remote="carRemote" @change="changeCar">
                <template>
                  <el-table-column label="车牌号码" prop="truckNo" />
                  <el-table-column label="车辆类型" prop="loadType">
                    <template slot-scope="{ row }">
                      {{ dictTruckTypeData.O[row.truckType]||'-' }}
                      <!-- /{{ row.extMap?row.extMap.loadType:'-' }}/{{ row.extMap?row.extMap.tractionHanger:'-' }} -->
                    </template>
                  </el-table-column>
                  <el-table-column label="核载(吨)" prop="truckLoadTon" />
                  <!-- <el-table-column label="准牵引总质量(kg)" prop="truckTotalTon" /> -->
                </template>
              </query-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="挂车号" prop="guaId">
              <query-select v-model="drawerForm.guaId" placeholder="请选择挂车号码" value-key="id" value-label="truckNo" :default-label="drawerForm.guaNo || ''" clearable :search-fields="selectSearchFields.trailer" :remote="trailerRemote" @change="changeTrailer">
                <template>
                  <el-table-column label="挂车车牌号码" prop="truckNo" />
                  <el-table-column label="车辆类型" prop="truckType">
                    <template slot-scope="{ row }">
                      <div>{{ dictTruckHangData.O[row.truckType] }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="核载(吨)" prop="truckLoadTon" />
                </template>
              </query-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item label="驾驶员" prop="driverId">
              <query-select v-model="drawerForm.driverId" placeholder="请选择驾驶员" value-key="driverUserId" value-label="driverName" :default-label="drawerForm.driverName || ''" clearable :search-fields="selectSearchFields.driver" :remote="driverRemote" @change="changeDriver">
                <template>
                  <el-table-column label="姓名" prop="driverName" />
                  <el-table-column label="手机" prop="driverPhone" />
                  <el-table-column label="身份证" prop="idCode" />
                </template>
              </query-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="driverPhone">
              <el-input v-model="drawerForm.driverPhone" placeholder="请选择驾驶员" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item label="押运员" prop="escortId">
              <query-select v-model="drawerForm.escortId" placeholder="请先选择押运员" value-key="id" value-label="driverName" :default-label="drawerForm.escortName || ''" clearable :search-fields="selectSearchFields.escort" :remote="escortRemote" @change="changeEscort">
                <template>
                  <el-table-column label="姓名" prop="driverName" />
                  <el-table-column label="手机" prop="driverPhone" />
                  <el-table-column label="身份证" prop="idCode" />
                </template>
              </query-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="escortPhone">
              <el-input v-model="drawerForm.escortPhone" placeholder="请先选择押运员" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="drawerForm.remark" type="textarea" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc==='add'||drawerTypeDesc==='edit'" type="primary" @click="submit">提交</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="danger" @click="deleteClick">删除</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="primary" @click="drawerTypeDesc = 'edit'">编辑</el-button>
    </div>
  </el-drawer>
</template>

<script>
import LabelList from '@/views/basicData/product/labelList';
import { getTruck } from '@/api/truck';
import { getTrailer } from '@/api/trailer';
import { getDriver } from '@/api/driver';
import { getEscort } from '@/api/escort';
import { addCapacity, getDetailCapacity, editCapacity, deleteCapacity } from '@/api/capacity';
import { getTypeCode } from '@/api/typeCode';
export default {
  components: { LabelList },
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    companyInfo: {
      type: Object,
      default() {
        return {
          // companyId: null,
        };
      },
    },
  },
  data() {
    return {
      drawerTypeDesc: '',
      drawerTitleObj: {
        'add': '新增运力关系',
        'edit': '编辑运力关系',
        'detail': '查看运力关系',
      },
      // 机动车车辆类型
      dictTruckTypeData: {
        A: [],
        O: {},
      },
      // 挂车车辆类型
      dictTruckHangData: {
        A: [],
        O: {},
      },
      drawerForm: {
        resType: [],
      },
      rules: {
        truckId: [{ required: true, message: '请选择车牌号码', trigger: 'change' }],
        driverId: [{ required: true, message: '请选择驾驶员', trigger: 'change' }],
      },
      labelList: [],
      selectSearchFields: {
        truck: [{ field: 'truckNo', label: '车牌号码' }],
        trailer: [{ field: 'truckNo', label: '挂车车牌号码' }],
        driver: [
          { field: 'driverName', label: '姓名' },
          { field: 'driverPhone', label: '手机' },
        ],
        escort: [
          { field: 'driverName', label: '姓名' },
          { field: 'driverPhone', label: '手机' },
        ],
      },
      initLabelData: [],
      emitSelectLabel: [],
    };
  },

  computed: {
    formDisabled() {
      return this.drawerTypeDesc === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
  },

  watch: {
    // 监听路由变化，如果发生变化则关闭drawer
    '$route': function(to, from) {
      this.drawerVisible = false;
    },
  },
  methods: {
    open() {
      this.drawerTypeDesc = this.drawerType;
      this.$nextTick(async() => {
        this.initLabelData = [];
        await this.$refs.labelList.getLabelData();
        await this.getDictionaryList();
        if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
          await this.getLabelData();
        }
      });
    },
    close() {
      this.drawerForm = {
        resType: [],
      };
      this.labelList = null;
      this.$refs.form.resetFields();
      this.$parent.getLabelData();
      this.$emit('update:drawerVisible', false);
    },
    // 获取数据字典数组
    async getDictionaryList() {
      const params = ['dict_truck_type', 'dict_truck_hang'];
      const data = await this.$store.dispatch('dictionary/get', params);
      if (data) {
        this.dictTruckTypeData = data['dict_truck_type'];
        this.dictTruckHangData = data['dict_truck_hang'];
      }
    },
    // 获取标签数组
    getLabelData() {
      if (this.$parent.labelList) {
        this.labelList = this.$parent.labelList || [];
        this.getDetail();
      } else {
        const params = {
          resType: 6,
        // page: 1,
        // size: 999,
        };
        getTypeCode(params).then(res => {
          this.labelList = res || [];
        }).finally(() => {
          this.getDetail();
        });
      }
    },
    selectLabel(e) {
      this.emitSelectLabel = e;
      this.drawerForm.resType = JSON.parse(JSON.stringify(e.map((item) => item.resValue)));
    },
    handleVisibleChange(visible) {
      if (visible) {
        // 加载下拉表格的数据
        this.$refs.labelList.getLabelData();
        // this.initLabelData = this.emitSelectLabel.map(d => d.resValue);
      }
    },
    // 选定标签事件c
    changeLabel(val) {
      this.initLabelData = val;
    },
    // 分页获取车辆信息
    carRemote(query) {
      return getTruck({
        ...query,
        createUser: this.$store.state.common.AUV_USER_ID,
        companyId: this.companyInfo.companyId,
      }).then((res) => {
        return {
          data: res?.records || [],
          total: res?.total || 0,
        };
      });
    },
    // 分页获取挂车信息
    trailerRemote(query) {
      return getTrailer({
        ...query,
        createUser: this.$store.state.common.AUV_USER_ID,
        companyId: this.companyInfo.companyId,
      }).then((res) => {
        return {
          data: res?.records || [],
          total: res?.total || 0,
        };
      });
    },
    // 分页获取司机信息
    driverRemote(query) {
      return getDriver({
        // dataType: 0, // 数据类型-0-驾驶员，1-押运员
        ...query,
        createUser: this.$store.state.common.AUV_USER_ID,
        companyId: this.companyInfo.companyId,
      }).then((res) => {
        return {
          data: res?.records || [],
          total: res?.total || 0,
        };
      });
    },
    // 分页获取押运员
    escortRemote(query) {
      return getEscort({
        // dataType: 1, // 数据类型-0-驾驶员，1-押运员
        ...query,
        createUser: this.$store.state.common.AUV_USER_ID,
        companyId: this.companyInfo.companyId,
      }).then((res) => {
        return {
          data: res?.records || [],
          total: res?.total || 0,
        };
      });
    },
    changeCar(value, data) {
      this.drawerForm.truckNo = data?.truckNo || null;
      this.drawerForm.truckLoadTon = data?.truckLoadTon || '';
    },
    changeTrailer(value, data) {
      this.drawerForm.guaNo = data?.truckNo || null;
      this.drawerForm.guaLoadTon = data?.truckLoadTon || '';
    },
    // 驾驶员选定拉出驾驶员手机号码
    changeDriver(value, data) {
      this.drawerForm.driverPhone = data?.driverPhone || null;
      this.drawerForm.driverName = data?.driverName || null;
      this.drawerForm.driverIdcode = data?.idCode || null;
    },
    changeEscort(value, data) {
      this.drawerForm.escortPhone = data?.driverPhone || null;
      this.drawerForm.escortName = data?.driverName || null;
      this.drawerForm.escortIdcode = data?.idCode || null;
    },
    getDetail() {
      getDetailCapacity({ id: this.drawerId }).then(res => {
        const newRes = JSON.parse(JSON.stringify(res));
        // 回显运力标签
        let matchLabel;
        if (newRes.resType) {
          // this.$refs.labelList.getLabelData();
          matchLabel = this.labelList.filter(item => newRes.resType.split(',').includes(item.resCode));
          // this.emitSelectLabel = matchLabel || [];
          newRes.resType = matchLabel.map(d => d.resValue);
          this.initLabelData = newRes.resType;
        }
        this.drawerForm = newRes || {};
        // this.drawerForm.resType = matchLabel.map(d => d.resValue);
      });
    },
    deleteClick() {
      const params = {
        id: this.drawerId,
      };
      deleteCapacity(params).then(res => {
        // if (res) {
        this.$baseMessage('删除成功!', 'success');
        this.$emit('drawerSubmit');
        this.close();
        // }
      });
    },
    submit() {
      // type 获取type 分别对应api提交
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            const drawerForm = JSON.parse(JSON.stringify(this.drawerForm));
            if (drawerForm.resType.length) {
              const needLabelArray = this.emitSelectLabel.map(d => d.resCode) || [];
              if (needLabelArray.length) {
                drawerForm.resType = needLabelArray.join(',');
              }
            } else {
              drawerForm.resType = '';
            }
            const apiMap = {
              'add': addCapacity,
              'edit': editCapacity,
            };
            const res = await apiMap[this.drawerTypeDesc](drawerForm);
            if (res) {
              this.$baseMessage(`${this.drawerTitle}成功!`, 'success');
              this.$emit('drawerSubmit');
              this.close();
            }
          } catch (error) {
            console.log(error);
          }
          // const addParams = {
          //   ...this.companyInfo,
          //   ...drawerForm,
          //   // resType: 6,
          // };
        }
      });
    },

  },
};

</script>
<style lang='scss' scoped>
.phone-number{
  font-size: 12px;
  padding-left: 130px;
  margin: -5px 0 10px 0;
  color:#909394;
}
</style>
