import request from '@/plugins/axios';
// function pickTrue(obj) {
//   const newObj = {
//     ...obj,
//   };
//   Object.keys(newObj).forEach((item) => {
//     if (!newObj[item] && newObj[item] !== 0) {
//       delete newObj[item];
//     }
//   });
//   return newObj;
// }
/* ----------【all】分页车辆------------ */
export const getTruckOld = (data) => {
  return request.post(`/resource/truck/list`, data).then((res) => {
    return res;
  });
};
export const getTruck = (data) => {
  return request.post(`/resource/middle/truck/list`, data).then((res) => {
    return res;
  });
};
export const addTruck = (data) => {
  return request.post('/resource/truck/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const getDetailTruck = (data) => {
  return request.get('/resource/truck/detail', data).then((res) => {
    return res;
  });
};
export const editTruck = (data) => {
  return request.post('/resource/truck/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteTruck = (data) => {
  return request.post('/resource/truck/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteTruck = (data) => {
  return request.post('/resource/truck/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
// 车辆状态数量统计
export const truckStateTotal = (data) => {
  return request.post('/resource/truck/state/total', data, { showLoading: true }).then((res) => {
    return res;
  });
};

