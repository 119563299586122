import request from '@/plugins/axios';
/* ----------【all】分页车辆------------ */
export const getCapacity = (data) => {
  return request.post(`/resource/trans/rel/list`, data).then((res) => {
    return res;
  });
};
export const addCapacity = (data) => {
  return request.post('/resource/trans/rel/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const getDetailCapacity = (data) => {
  return request.get('/resource/trans/rel/detail', data).then((res) => {
    return res;
  });
};
export const editCapacity = (data) => {
  return request.post('/resource/trans/rel/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteCapacity = (data) => {
  return request.post('/resource/trans/rel/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteCapacity = (data) => {
  return request.post('/resource/trans/rel/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
// 车辆状态数量统计
export const capacityStateTotal = (data) => {
  return request.post('/resource/trans/rel/state/total', data, { showLoading: true }).then((res) => {
    return res;
  });
};

