<template>
  <div class="basic-data-wrap flex">
    <leftPanel ref="leftPanel" :class="['left-wrap',isFold?'left-half':'left-all']" :is-fold.sync="isFold" :company-type="2" @changeCompany="changeCompany" @leftCompanyData="leftCompanyData" />
    <div :class="['right-wrap  g-search-form-table',isFold?'right-half':'right-all']">
      <search-form id="search-form" :model="form" :count="4" label-width="auto" :collapse="false" @search="searchHandle">
        <search-item label="" prop="truckNo">
          <el-input v-model.trim="form.truckNo" clearable placeholder="车牌号" />
        </search-item>
        <search-item label="" prop="guaNo">
          <el-input v-model.trim="form.guaNo" clearable placeholder="挂车号" />
        </search-item>
        <search-item label="" prop="driverName">
          <el-input v-model.trim="form.driverName" clearable placeholder="驾驶员" />
        </search-item>
        <search-item label="" prop="escortName">
          <el-input v-model.trim="form.escortName" clearable placeholder="押运员" />
        </search-item>
      </search-form>
      <Table
        :loading="loading"
        :table-height="tableHeight"
        :show-selection-change="true"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-btns="tableBtns"
        :pagination="pagination"
        :multiple-selection.sync="multipleSelection"
        @selectTime="selectTime"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <template #headOther>
          <div id="label-list" class="label-wrap flex-b-c">
            <div class="left flex">
              <div v-for="(item, index) in labelList" :key="index" :class="{ active: index === labelActiveIndex }" @click="labelClick(item, index)">
                <span>{{ item.resValue }}</span>
                <span> {{ item.resTotal }}</span>
              </div>
            </div>
            <div class="right main-c" @click="dialogVisible = true">管理运力标签</div>
          </div>
        </template>
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'driverName'" class="ellipsis">
            <span class="main-c">{{ row.driverName }}</span>
            <span> {{ row.driverPhone }}</span>
          </div>
          <!-- <div v-if="col.prop === 'driverName'" class="ellipsis">
            <span class="main-c">{{ row.driverName }}</span>
            <span> {{ row.driverPhone }}</span>
          </div> -->
          <div v-if="col.prop === 'escortName'" class="ellipsis">
            <span class="main-c">{{ row.escortName }}</span>
            <span> {{ row.escortPhone }}</span>
          </div>
          <div v-if="col.prop === 'truckLoadTon'" class="ellipsis">
            <span v-if="row.truckLoadTon||row.guaLoadTon"> {{ row.truckLoadTon?row.truckLoadTon:row.guaLoadTon }}</span>
            <span v-else>-</span>
          </div>
        </template>
      </Table>
      <Drawer :drawer-visible.sync="drawerVisible" :drawer-type="drawerType" :drawer-id="id" :company-info="companyInfo" @drawerSubmit="drawerSubmit" />

      <LabelDialog :dialog-visible.sync="dialogVisible" />
    </div>
  </div>
</template>

<script>
import { getCapacity, batchDeleteCapacity } from '@/api/capacity';
import { getTypeCode } from '@/api/typeCode';
import { exportByJson, parseTime } from '@/utils';
import { convertArrayToObject } from '@/utils/auv';
import leftPanel from '../components/leftPanel';
import Drawer from './drawer';
import LabelDialog from './labelDialog';
export default {
  name: 'BasicdataCapacity',
  components: { leftPanel, Drawer, LabelDialog },
  data() {
    return {
      isFold: false,
      tableHeight: 'auto',
      companyInfo: {}, // 当前所选的左侧公司信息 相关接口需要此参数
      loading: false,
      form: {},
      labelList: [],
      labelActiveIndex: 0,
      tableBtns: [
        {
          label: '新增运力组合',
          icon: 'el-icon-circle-plus-outline',
          evt: () => {
            this.openDialog('add');
          },
        },
        {
          label: '删除',
          type: 'danger',
          evt: () => {
            this.delete();
          },
        },
        {
          label: '导入',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
        {
          label: '导出',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
      ],
      tableCols: [
        { label: '运力标签', prop: 'resTypeDesc', width: 160 },
        { label: '车牌号', prop: 'truckNo', fixed: 'left', type: 'jump', evt: (data) => { this.openDialog('detail', data); } },
        { label: '挂车号', prop: 'guaNo', fixed: 'left', type: 'jump', evt: (data) => { this.openDialog('detail', data); } },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 160 },
        { label: '押运员', prop: 'escortName', type: 'customize', width: 160 },
        // { label: '核载(吨)', prop: 'truckLoadTon', type: 'customize', width: 160 },
        { label: '创建人', prop: 'createUsername' },
        { label: '创建时间 ', prop: 'createTime', type: 'filterTime', width: '160' },
        { label: '更新人', prop: 'updateUsername' },
        { label: '更新时间', prop: 'updateTime', sortable: 'custom', headTimeSelect: true, width: 170, type: 'filterTime' },
        {
          label: '操作',
          type: 'operation',
          fixed: 'right',
          btns: [
            {
              label: '编辑',
              evt: (data) => {
                this.openDialog('edit', data);
              },
            },
          ],
        },
      ],
      tableData: [],
      multipleSelection: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      id: null,
      drawerType: 'add',
      drawerVisible: false,
      dialogVisible: false,
      convertArrayToObjectData: {},
    };
  },
  created() {
    this.getLabelData();
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.handleResize();
    // });
    window.addEventListener('resize', this.handleResize, true);
  },
  deactivated() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  methods: {
    handleResize() {
      const screenHeight = document.documentElement.clientHeight;
      const formHeight = document.getElementById('search-form').clientHeight;
      const labelHeight = document.getElementById('label-list').clientHeight;
      const otherHeight = formHeight + labelHeight + 14 + 82 + 20 + 56 + 52 + 20;
      this.tableHeight = screenHeight - otherHeight + 'px';
    },
    // 获取标签数组
    getLabelData() {
      const params = {
        resType: 6,
        // page: 1,
        // size: 999,
      };
      getTypeCode(params).then((res) => {
        if (res) {
          res.unshift({
            resCode: '',
            resTotal: res.length,
            resType: 6,
            resValue: '全部',
          });
        }
        this.labelList = res || [];
      }).finally(() => {
        this.$nextTick(() => {
          this.convertArrayToObjectData = convertArrayToObject(this.labelList, 'resCode', 'resValue');
          this.handleResize();
        });
      });
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: this.pagination.page,
        size: this.pagination.size,
      };
      getCapacity(params)
        .then((res) => {
          // resType
          let newRecords;
          if (res?.records) {
            newRecords = res.records.map(d => ({
              ...d,
              resTypeDesc: d.resType ? d.resType.split(',').map(d => this.convertArrayToObjectData[d] || '-').join(',') : '',
            }));
          } else {
            newRecords = [];
          }
          this.tableData = newRecords || [];
          this.pagination = {
            page: res?.current || 1,
            size: res?.size || 10,
            total: res?.total || 0,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询按钮操作
    searchHandle() {
      // 防止点击重置置空以下参数影响筛选
      this.form.companyId = this.companyInfo.companyId; // 企业id
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 删除所选
    delete() {
      if (!this.multipleSelection.length) {
        this.$baseMessage('您还未勾选,请先勾选!', 'error');
        return;
      }
      const params = {
        ids: this.multipleSelection.map((d) => d.id) || null,
      };
      batchDeleteCapacity(params).then((res) => {
        this.$baseMessage('删除成功!', 'success');
        this.getList();
      });
    },
    // 前端导出excel
    onExport() {
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: 1,
        size: 999,
      };
      getCapacity(params)
        .then((res) => {
          if (!res) {
            this.$baseMessage('暂无数据可导出!', 'error');
            return;
          }
          const header = ['车牌号', '挂车号', '驾驶员', '押运员', '创建人', '创建时间', '更新人', '更新时间'];
          const exportData = res.records.map((item) => {
            return {
              truckNo: item.truckNo,
              guaNo: item.guaNo || '-',
              driverName: item.driverName || '-' + item.driverPhone || '-',
              escortName: item.escortName || '-' + item.escortPhone || '-',
              // truckLoadTon: item.truckLoadTon || '-',
              createUsername: item.createUsername,
              createTime: parseTime(item.updateTime),
              updateUsername: item.updateUsername,
              updateTime: parseTime(item.updateTime),
            };
          });
          exportByJson(exportData, header, '运力列表.xlsx');
        })
        .catch((err) => {
          console.log(err);
          this.$baseMessage('导出失败', 'error');
        });
    },
    selectTime(time) {
      this.form.updateTimeBegin = time ? time[0] : '';
      this.form.updateTimeEnd = time ? time[1] : '';
      this.getList();
    },
    // 打开侧面抽屉 进行新增/编辑/查看操作
    openDialog(type, data) {
      this.id = data?.id || null;
      this.drawerType = type;
      this.drawerVisible = true;
    },
    // 抽屉提交事件
    drawerSubmit() {
      this.getList();
      this.getLabelData();
    },
    //  标签点击
    labelClick(data, index) {
      this.labelActiveIndex = index;
      this.form.resType = data?.resCode || '';
      this.getList();
    },
    leftCompanyData(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
    },
    // 左侧切换公司 重新发起请求
    changeCompany(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/basicData.scss";
.label-wrap {
  margin-bottom: 10px;
  .left {
    flex-wrap: wrap;
    > div {
      margin-bottom: 10px;
      margin-right: 6px;
      padding: 4px 10px;
      border-radius: 4px;
      background-color: rgba(99, 102, 108, 0.12);
      color: rgba(21, 24, 29, 0.64);
      // #E6F3FF 100%
      font-size: 14px;
      cursor: pointer;
    }
    .active {
      background-color: #e6f3ff;
      color: $blue;
    }
  }
  .right {
    min-width: 102px;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
